/**
 * getTillitIdentityLink
 *
 * @todo - setup to be env specific.
 */
export function getTillitIdentityLink() {
  return "https://identity.tillitinvest.com";
}

/**
 * getTillitWebsiteLink
 *
 * @todo - setup to be env specific.
 */
export function getTillitWebsiteLink() {
  return "https://tillitinvest.com";
}

/**
 * getTillitAppLink
 *
 * @todo - setup to be env specific.
 */
export function getTillitAppLink() {
  return `https://my.tillitinvest.com`;
}

/**
 * getTillitSignUpLink
 */
export function getTillitSignUpLink() {
  return `${getTillitAppLink()}/register?ReturnPath=/open-account `;
}

/**
 * getTillitSignInLink
 */
export function getTillitSignInLink() {
  return `${getTillitAppLink()}/login`;
}

/**
 * getTillitTransferPageLink
 */
export function getTillitTransferPageLink() {
  return `${getTillitWebsiteLink()}/transfers`;
}
