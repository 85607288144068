import * as React from "react";
import { SliceContainer } from "../components/design-system/Slices/SliceContainer/SliceContainer";
import { colors } from "../theme/theme";
import { Layout } from "../components/layout/Layout";
import { Container } from "../components/design-system/Container/Container";
import { getTillitWebsiteLink } from "../helpers/tillitLinks";
import { Seo } from "../components/Seo";
import { withPrismicUnpublishedPreview } from "gatsby-plugin-prismic-previews";
import styled from "styled-components";
import { H1 } from "../components/design-system/Typography/headings/Headings";
import { PureTextRow } from "../components/design-system/Slices/SliceTextRow/SliceTextRow";
import { RowSizeLookup, TextAlignment } from "../components/_shared";

const allowPreview = process.env.GATSBY_ALLOW_PREVIEW == "true";

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  min-height: 80vh;
  padding: 0;

  section {
    padding-top: 0;
  }
`;

const NotFoundPage = () => {
  return (
    <Layout>
      <main>
        <SliceContainer>
          <StyledContainer>
            <PureTextRow
              isFullWidth={false}
              rowSize={RowSizeLookup.Medium}
              alignment={TextAlignment.center}
            >
              <H1 color={colors.magenta} center>
                Sorry.
              </H1>
              <p>
                Looks like something has gone wrong on our end. Please use the
                menu to go to a different page, or the button below to take you
                back to the homepage.
              </p>
              <p>
                <a href={getTillitWebsiteLink()}>Return to homepage</a>
              </p>
            </PureTextRow>
          </StyledContainer>
        </SliceContainer>
      </main>
    </Layout>
  );
};

export default allowPreview
  ? withPrismicUnpublishedPreview(NotFoundPage)
  : NotFoundPage;

export const Head = () => (
  <Seo
    title="Page not found"
    description="Looks like something has gone wrong on our end. Please use the
  menu to go to a different page, or the button below to take you
  back to the homepage."
  />
);
